export interface BodyPart {
    id: string;
    name: string;
}

export interface Category {
    id: string;
    name: string;
}

export interface Metric {
    id: string;
    name: string;
}

export interface AppliedMetric {
    metric: Metric;
    value: string | number;
}

export type Video = {
    url: string;
};

export type Image = {
    url: string;
};

export type Description = {
    text?: string;
    video?: Video;
};

export interface ExerciseModel {
    id: string;
    name: string;
    imageUrl?: string;
    description: Description;
    fromUserId: string | null;
    bodyParts: BodyPart[];
    categories: Category[];
    metrics: Metric[];
    created_at?: string;
    _deleted?: boolean;
}

export type Duration = number;

export interface ExerciseSet {
    metrics: AppliedMetric[];
    rest: Duration;
    completed?: boolean;
}

export interface SingleExerciseEntry {
    exercise: ExerciseModel;
    sets: ExerciseSet[];
}

export interface CombinedExerciseEntry {
    exercises: SingleExerciseEntry[];
    circuit?: boolean;
    repetitions: number;
    rest: Duration;
}

export type ExerciseEntry = SingleExerciseEntry | CombinedExerciseEntry;

export enum RoutineEntryType {
    CUSTOM = 'CUSTOM',
    WARMUP = 'WARMUP',
    STRENGTH = 'STRENGTH',
    SKILL = 'SKILL',
    PREHAB = 'PREHAB',
    COOLDOWN = 'COOLDOWN',
    STRETCHING = 'STRETCHING',
}

export interface RoutineEntry {
    id: string;
    type: RoutineEntryType;
    exerciseEntries: ExerciseEntry[];
}

export type ValueSuggester = { forMetric: (m: Metric) => string; forRest: () => number };

export type TextSelectionItem = {
    value: string;
    text: string;
};

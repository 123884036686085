import {
    ExerciseEntry,
    ExerciseModel,
    Metric,
    RoutineEntry,
    RoutineEntryType,
    SingleExerciseEntry,
} from '../../components/types';
import { CombinedExerciseEntryData, SingleExerciseEntryData } from '../../services/mutations';
import { GetWorkoutsResponse, RoutineEntryData, Workout } from '../../types/api';

export function formWorkouts(getWorkoutsResponse: GetWorkoutsResponse): Workout[] {
    const workouts: Workout[] = [];

    for (const w of getWorkoutsResponse.workouts) {
        workouts.push({
            id: w.id,
            name: w.name,
            fromUserId: w.fromUserId,
            routineId: w.routineId,
            entries: w.entries.map((e) =>
                routineEntryDataToRoutineEntry(e, {
                    exercises: getWorkoutsResponse.exercises,
                    metrics: getWorkoutsResponse.metrics,
                })
            ),
            totalDurationInSeconds: w.totalDurationInSeconds,
            created_at: w.created_at,
        });
    }

    return workouts;
}

export function routineEntryDataToRoutineEntry(
    entry: RoutineEntryData,
    {
        metrics,
        exercises,
    }: {
        metrics: Record<string, Metric>;
        exercises: Record<string, ExerciseModel>;
    }
): RoutineEntry {
    const exerciseEntries: ExerciseEntry[] = [];

    for (const ee of entry.exerciseEntries) {
        if ((ee as CombinedExerciseEntryData).exercises) {
            const ceed = ee as CombinedExerciseEntryData;
            exerciseEntries.push({
                exercises: ceed.exercises.map((el) => singleExerciseEntryDataToEntry(el, metrics, exercises)),
                circuit: ceed.circuit,
                repetitions: ceed.repetitions,
                rest: ceed.rest,
            });
        } else {
            exerciseEntries.push(singleExerciseEntryDataToEntry(ee as SingleExerciseEntryData, metrics, exercises));
        }
    }

    return {
        id: entry.id,
        type: RoutineEntryType[entry.type as keyof typeof RoutineEntryType],
        exerciseEntries: exerciseEntries,
    };
}

function singleExerciseEntryDataToEntry(
    el: SingleExerciseEntryData,
    metrics: Record<string, Metric>,
    exercises: Record<string, ExerciseModel>
): SingleExerciseEntry {
    return {
        exercise: exercises[el.exerciseId] ?? deletedExercise(),
        sets: el.sets.map((seel) => ({
            completed: seel.completed,
            metrics: seel.metrics.map((mel) => ({
                value: mel.value,
                metric: metrics[mel.id] as unknown as Metric,
            })),
            rest: seel.rest,
        })),
    };
}

function deletedExercise(): ExerciseModel {
    return {
        id: '',
        name: 'Deleted Exercise',
        description: {},
        fromUserId: null,
        bodyParts: [],
        categories: [],
        metrics: [],
        _deleted: true,
    };
}

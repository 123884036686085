import { useMutation, useQuery } from 'react-query';
import { MutationResultPair, QueryConfig, QueryResult } from 'react-query/types/core/types';

import { createModule } from '@/c/data/common';
import { RoutinesAndFolders, formRoutines } from '@/c/data/mappers/routinesMapper';
import { CreateUpdateRoutineRequest, createRoutine, deleteRoutine, updateRoutine } from '@/c/services/mutations';
import { getRoutine, getRoutines } from '@/c/services/queries';
import { Routine } from '@/c/types/api';

type T = Routine;
const ITEM_KEY = 'routine';
const COLLECTION_KEY = 'routines';

const { addItem, deleteItem, updateItem } = createModule<T>({
    itemKey: ITEM_KEY,
    collectionKey: COLLECTION_KEY,
});

export const useRoutine = (id: string, queryConfig?: QueryConfig<T, Error>): QueryResult<Routine, Error> =>
    useQuery<T, Error>([ITEM_KEY, id], () => getRoutine(id), queryConfig);

export const useRoutines = (): QueryResult<RoutinesAndFolders, Error> =>
    useQuery<RoutinesAndFolders, Error>(COLLECTION_KEY, () => getRoutines().then(formRoutines));

export const useCreateRoutineMutation = (): MutationResultPair<T, Error, CreateUpdateRoutineRequest, unknown> =>
    useMutation(createRoutine, {
        onSuccess: addItem,
    });

export const useUpdateRoutineMutation = (): MutationResultPair<T, Error, CreateUpdateRoutineRequest, unknown> =>
    useMutation(updateRoutine, {
        onSuccess: updateItem,
    });

export const useDeleteRoutineMutation = (): MutationResultPair<Response, Error, string, unknown> =>
    useMutation(deleteRoutine, {
        onSuccess: (data, id) => {
            data.status === 200 && deleteItem(id);
        },
    });

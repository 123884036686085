import React from 'react';

import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import { SSRConfig, withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';

import { Action, Scope, Subject } from '@/c/auth/permissions';
import { formPermission } from '@/c/auth/utils';
import withPermissionsGate from '@/c/auth/withPermissionsGate';
import NetworkStatus from '@/c/components/molecules/NetworkStatus';
import RoutinesPage from '@/c/components/pages/RoutinesPage/RoutinesPage';
import { DEFAULT_LOCALE } from '@/c/constants/locales';
import { useCreateRoutineMutation, useDeleteRoutineMutation, useRoutines } from '@/c/data/routines';
import { croutes } from '@/c/routes';
import { routineToCreateUpdateRequest } from '@/c/services/mutations';
import { scopedT } from '@/c/translations/utils';
import { useRouter } from '@/core/router';

import { linkRendered } from '../../core/linkRenderer';
import { getRouteSource } from '../../getRouteSource';
import { NextPageProps } from '../../types/NextPageProps';

const T_NAMESPACE = 'routines-page';

const Page = ({ t }: NextPageProps) => {
    const nt = scopedT(t, T_NAMESPACE);
    const router = useRouter();
    const { data, status, error, refetch } = useRoutines();

    const [createRoutineMutation] = useCreateRoutineMutation();
    const [deleteRoutineMutation] = useDeleteRoutineMutation();

    return (
        <>
            <Head>
                <title>{nt('pageTitle')}</title>
            </Head>
            <main>
                <NetworkStatus
                    i18n={{ retryBtn: t('retryFetchBtn') }}
                    status={status}
                    error={error}
                    retryFn={refetch}
                />
                {data && (
                    <RoutinesPage
                        routines={data.routines}
                        onCreateClick={() => {
                            router.push(getRouteSource(croutes.ROUTINE_CREATE));
                        }}
                        onEditClick={(id: string) => {
                            router.push(
                                getRouteSource({
                                    pathname: croutes.ROUTINE_EDIT,
                                    query: {
                                        id: id,
                                    },
                                })
                            );
                        }}
                        onDuplicateClick={async (routine) => {
                            const duplicate = {
                                ...routine,
                                name: `${routine.name} Copy`,
                            };
                            await createRoutineMutation(routineToCreateUpdateRequest(duplicate));
                        }}
                        onDeleteClick={async (routineId) => {
                            const response = (await deleteRoutineMutation(routineId)) as Response;
                            if (response.status !== 200) {
                                alert(await response.statusText);
                            }
                        }}
                        renderLink={linkRendered}
                    />
                )}
            </main>
        </>
    );
};

const tNamespaces = ['common', 'header', 'footer', T_NAMESPACE];

export async function getStaticProps({ locale }: GetStaticPropsContext): Promise<GetStaticPropsResult<SSRConfig>> {
    return {
        props: {
            ...(await serverSideTranslations(locale || DEFAULT_LOCALE, tNamespaces)),
        },
    };
}

export const routinesRequiredPermissions = [
    formPermission(Subject.ROUTINE, Action.LIST, Scope.SELF),
    formPermission(Subject.ROUTINE, Action.VIEW, Scope.SELF),
];

export default withPermissionsGate(withTranslation(tNamespaces)(Page), routinesRequiredPermissions);
